<template>
  <div>
    <v-card-text class="pt-5">
      <v-form
        ref="form"
        @keyup.native.enter="submit"
      >
        <v-text-field
          v-model="authentication.login"
          label="Usuário"
          type="text"
          :rules="$rules.required"
        />
        <v-text-field
          v-model="authentication.password"
          label="Senha"
          type="password"
          :rules="$rules.required"
        />
      </v-form>
      <div class="text-center my-2">
        <v-btn
          text
          color="blue"
          @click="dialog=true"
        >
          Esqueci minha senha
        </v-btn>
      </div>
    </v-card-text>
    <v-card-actions class="d-flex justify-center">
      <v-btn
        color="secondary"
        @click="submit"
      >
        Enviar
      </v-btn>
    </v-card-actions>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Troca de senha
        </v-card-title>

        <v-card-text class="mt-3">
          Para efetuar a troca de senha, entre em contato:
          <div class="d-flex justify-center flex-column my-5">
            <div class="text-center">
              servicedesk@cassems.com.br
            </div>
            <div class="text-center">
              (67) 3309-5360
            </div>
          </div>
          Ou, use o botão de auto atendimento para alterar a senha.
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                color="secondary"
                text
                href="https://sistemas.cassems.com.br/RDWeb/Pages/pt-BR/password.aspx"
                target="_blank"
                v-bind="attrs"
                v-on="on"
              >
                Auto atendimento
              </v-btn>
            </template>
            <span>
              Caso saiba sua senha
            </span>
          </v-tooltip>
          <v-spacer />
          <v-btn
            color="secondary"
            text
            @click="dialog = false"
          >
            Entendi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import jwt_decode from 'jwt-decode';
import { mapActions } from 'vuex';
import LoginService from '@/services/login-service';

export default {
  data() {
    return {
      authentication: {
        login: '',
        password: '',
      },
      dialog: false,
    };
  },
  methods: {
    ...mapActions('auth', ['login']),
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          const data = await LoginService.login(this.authentication);
          const { roles } = jwt_decode(data.token);
          if (!roles || !roles.includes('cm-admin')) {
            this.$toast.error(
              'Você não possui permissão para acessar o sistema CM-Admin',
            );
          } else {
            await this.login({ ...data, roles });
            this.$router.push({ name: 'main.home' });
          }
        } catch (e) {
          this.$handleHttpError(e);
        }
      }
    },
  },
};
</script>

<style></style>
